<template>
  <div>
    <div class="news-wrap page-width">
      <div class="news-main">
        <div class="article-container">
          <p class="title-box">
            <span class="article-tag" v-if="articleDetail.selection === 1">{{$t('news.featured')}}</span>
            <span class="article-title mon-text-overflow-2">{{ articleDetail[getLangType('title')] }}</span>
          </p>
          <p class="content">{{ articleDetail[getLangType('introduction')] }}</p>
          <div class="info" style="padding:5px 0">
            <div class="publish-time column">{{ articleDetail.created_at }}</div>
            <!-- <div class="origin">{{ item.category_name }}</div> -->
            <div class="column">{{ articleDetail.source }}</div>
            <div class="column">{{ allTypes(articleDetail.category_id) }}</div>
          </div>
        </div>
        <div style="margin-bottom:40px;margin-top:20px;" v-html="articleDetail[getLangType('content')]"></div>
      </div>
      <div class="select-wrap">
        <el-input :placeholder="$t('base.pleaseInput')" prefix-icon="el-icon-search" class="search-enter-wrap" v-model="title" @keyup.enter.native="getValueKey()">
        </el-input>
        <div class="select-wrap-title">
          <span class="fs-26 font-w-bold">{{ $t('news.recommendReading') }}</span>
        </div>
        <div v-for="(item, index) in editSelect" @click="tableData(item)" :key="index">
          <div class="d-flex padBottomData">
            <div class="select-wrap-title number-text-Data">
              <span class="number-text">{{ index+1 >= 10 ? index+1 : '0'+(index+1) }}</span>
            </div>
            <div class="select-item-content">
              <h2 class="select-title">
                <p class="title-text">{{ item[getLangType('title')] }}</p>
              </h2>
              <div class="select-item-info">
                <div class="publish-time">{{ item.created_at }}</div>
                <!-- <div class="column">{{ item.source }}</div> -->
                <div class="column">{{ allTypes(item.category_id)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-module home-banner">
      <div class="home-module-word">
        <div class="home-module-title">
          <h2 class="home-module-title">{{$t('home.model6Title')}}</h2>
        </div>
        <button class="cta cursor-pointer" @click="toSettled()">
          <span class="hover-underline-animation">{{$t('home.moveInNow')}}</span>
          <i class="el-icon-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      title: '',
      dynamicList: [],
      editSelect: [],
      templateData: '',
      articleDetail: {},
    }
  },
  mounted() {
    this.getNewData()
    this.getValueKey()
  },
  computed: {
    getLangType() {
      return function (key) {
        switch (this.$i18n.locale) {
          case 'en_US':
            return 'en_' + key
          case 'zh_CN':
            return key
          default:
            return key
        }
      }
    },
    allTypes() {
      return function (value) {
        const list = [
          {value: '', typeName: this.$t('newStatus.allStatus')},
          {value: 1, typeName: this.$t('newStatus.monmallNews')},
          {value: 2, typeName: this.$t('newStatus.marketInsight')},
          {value: 3, typeName: this.$t('newStatus.operatingDryGoods')},
          {value: 4, typeName: this.$t('newStatus.monmallActive')},
        ]
        if (value) {
          return list.filter(item => item.value === value)[0].typeName
        } else {
          return list
        }
      }
    }
  },
  methods: {
    getNewData() {
      this.$api.newDetails(this.$route.query.id).then(res => {
        this.articleDetail = res.data
      })
    },
    getValueKey() {
      const data = {
        title: this.title
      }
      this.$api.newInfo(data).then(res => {
        this.editSelect = res.data.selection
      })
    },
    tableData(item) {
      this.$route.query.id = item.id
      this.$api.newDetails(item.id).then(res => {
        this.articleDetail = res.data
      })
    },
    toSettled() {
      this.$router.push({ path: '/settled' })
    },
  }
}
</script>

<style scoped>
.bannerData .el-tabs__header {
  margin-bottom: 0 !important;
}
.news-main {
  width: 60%;
}
.article-title {
  font-size: 24px;
}
/*@media screen and (max-width: 997px) {*/
/*  .news-wrap {*/
/*    padding-top: 50px;*/
/*  }*/
/*  .select-wrap {*/
/*    display: none;*/
/*  }*/
/*  .page-width {*/
/*    width: 90%;*/
/*  }*/
/*  .news-main {*/
/*    width: 100%;*/
/*  }*/
/*}*/
</style>
